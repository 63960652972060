//module
a{color:$BLUE;text-decoration: none;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: opacity 0.3s $ease7;}
	}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;	width: auto;}
.fl-right {	float: right;	width: auto;}
.cl{clear:both;}

//フォントサイズ
.l{font-size:1.8rem;}
.s{font-size:1.2rem;}
.xs{font-size:1.0rem;}

//フォントカラー
.red{	color: $RED;}



.tbdisp{display: none;}
.spdisp{display: none;}


.tit{
	text-align: center;
	&-en{
		@extend .serif;font-size: 7rem;color: $GREEN;display: block;line-height: 1;margin-bottom: 20px;
	}
	&-jp{
		@extend .noto;display: block;font-size: 2rem;color: $BLACK;line-height: 1;
	}
}

.anm{
	opacity: 0;transition:opacity 0.5s $ease5;
	&.is-show{
		opacity: 1;
	}
}

.img-anime{
	overflow: hidden;position: relative;
	img{opacity: 0;}
	&:before{
		content:"";position: absolute;top:0;left:0;width: 100%;height: 100%;background: $GREEN;z-index:1;
	}
}
.is-show{
	.img-anime{
		&:before{
			animation: img-anime 1.2s $ease9 0.1s 1 normal both;
		}
		img{opacity: 1;transition:opacity 0s linear 0.7s;}
	}
}