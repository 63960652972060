//tablet
.tbdisp{display: block;}
.tbhide{display: none;}

#wrapper{
	min-width: 0;
}
header{
	height: 80px;
}
.logo{
	top: 22px;left:30px;
}

#menu-btn{
	display: block;width: 75px;height: 75px;background: $GREEN;
	position: absolute;right:0;top:0;cursor: pointer;z-index: 999;overflow: hidden;
	.menu-line{display: block;width: 31px;height: 1px;
		background: #fff;position: absolute;left:calc(50% - 15.5px);
		&:nth-child(1){top:29px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
		&:nth-child(2){top:38px;transition: left 0.1s $ease2 0s;}
		&:nth-child(3){top:47px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
	}
}
.navi-wrap{
	display: block;visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
	width: 280px;height: 100%;padding: 80px 0;background: rgba(darken($GREEN,12),0.85);backdrop-filter:blur(3px);
	overflow: auto;	-webkit-overflow-scrolling:touch;
	position: fixed;z-index: 998;top:0;right:0;
}
.gnavi{
	width: 100%;
	&-lst{
		display: block;
		li{border: none;border-top: 1px solid rgba(255,255,255,0.2);}
	}
	a{
		padding: 30px 0 0;
	}
	&-jp{color: #fff;font-size: 1.8rem;}
	&-en{color: #fff;}
}
#menu.open{
	#menu-btn{
		background: transparent;position: fixed;top:5px;
		.menu-line{
			&:nth-child(1){top:38px;transform: rotate(-135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
			&:nth-child(2){left: -100px;}
			&:nth-child(3){top:38px;transform: rotate(135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}

.head-contact{
	&-tel{display: inline-block;font-size: 3rem;}
}
.page-top{
	display: block;text-align: center;
	a{display: block;padding: 20px 0;color: #fff;}
}	

.key{
	&-box{
		width: 90%;
	}
	&-tit{
		font-size: 3rem;
		.s{font-size: 2.6rem;top:-2px;}
	}
}

.ab{
	&-tit{
		margin-bottom: 40px;
	}
	&-box1{
		width: 100%;padding: 0 5%;border: none;margin-bottom: 30px;
	}
	&-img1{width: 100%;height: 0;padding-bottom: 40%;position: relative;left:0;top:0;overflow: hidden;margin-bottom: 30px;
		img{@include max(w);position: absolute;left:0;top:50%;transform: translateY(-50%);}
		
	}
	&-box2{
		width: 100%;padding: 0 5% 0 40%;font-size: 1.6rem;
	}
	&-img2{
		position: absolute;left:0;width: 35%;
		img{@include max(w);}
	}
	

}

.lw{
	&-box{
		width: 94%;padding: 3%;
	}
	&-img{
		width: 35%;
		img{@include max(w);}
	}
	&-prof{
		width: 60%;
	}
	&-tb{
		display: block;
		dt{width: 100%;margin-bottom: 0;line-height: 1;font-size: 1.3rem;}
		dd{width: 100%;}
	}	
}

.ov{
	&-box{
		width: 100%;padding: 0 5%;
	}
	&-map{
		padding-bottom: 75%;
	}
}