//mobile
body{font-size: 1.4rem;}
p img,figure img{@include max(w);}

.spdisp{display: block;}
.sphide{display: none;}

.xs{font-size: 1.0rem;}
.s{font-size: 1.1rem;}
.l{font-size: 1.6rem;}

table{
	&:not(.nobreak){
		tr,th,td{display: block;width: 100%;}
		th{margin-bottom: 5px;}
	}
}

.tit{
	&-en{
		font-size: 5.6rem;margin-bottom: 12px;
	}
	&-jp{
		font-size: 1.6rem;
	}
}

header{
	height: 60px;border: none;
}
.logo{
	top:18px;left:20px;
	img{
		width: 176px;height: auto;
	}
}

#menu-btn{
	width: 60px;height: 60px;position: fixed;
	.menu-line{width: 24px;
		left:calc(50% - 12px);
		&:nth-child(1){top:22px;}
		&:nth-child(2){top:30px;}
		&:nth-child(3){top:38px;}
	}
}
.navi-wrap{
	width: 70%;height: 100%;padding: 60px 0;
}
.gnavi{
	&-jp{font-size: 1.6rem;}
	&-en{font-size: 1.3rem;}
	a{
		height: 80px;padding-top: 24px;
	}
}

.head-contact{
	height: 80px;position: relative;padding-top: 12px;
	&-label{
		font-size: 1.4rem;padding: 3px 0;
	}
	&-tel{font-size: 2.6rem;}
	.tel-wrap{
		color: #fff;
		&:before{
			content:"";position: absolute;width: 100%;height:100%;top:0;left:0;
		}
	}
	
}
#menu.open{
	#menu-btn{
		top:2px;
		.menu-line{
			&:nth-child(1){top:30px;}
			&:nth-child(2){left: -60px;}
			&:nth-child(3){top:30px;}
		}
	}
}

.foot{
	.tel-wrap{
		color: #fff;text-decoration: underline;
	}
}
.copyright{
	font-size: 1rem;
}

.key{
	height: calc(100vh - 60px);
	&-box{
		padding: 50px 0;
		&:before{
			border-width: 50px;
		}
	}
	&-tit{
		font-size: 2.0rem;margin-bottom: 25px;
		.s{font-size: 1.6rem;}
	}
	&-txt{
		font-size: 1.4rem;
	}
}

.ab{
	padding: 60px 0;
	&-subtit{
		font-size: 1.6rem;
	}
	&-catch{
		font-size: 1.8rem;
	}
	&-box2{
		padding: 0 7%;font-size: 1.4rem;
	}
	&-img2{
		position: relative;top:0;left:0;width: 70%;margin: 0 auto;
	}
	&-lst{
		margin-bottom: 25px;
		li{padding-left: 12px;
			&:before{
				width: 4px;height: 4px;top:0.7em;
			}
		}
	}
}

.lw{
	padding-bottom: 60px;
	&-titbox{
		padding: 40px 0 70px;
	}
	&-box{
		margin-top: -30px;padding: 20px 6%;display: block;
	}
	&-img{
		width: 70%;margin: 0 auto 25px;height: 0;padding-bottom: 70%;overflow: hidden;position: relative;
		.img-anime{padding-bottom: 100%;}
		img{position: absolute;left:0;top:0;}
	}
	&-prof{
		width: 100%;
	}
	&-name{
		text-align: center;
	}
	&-kanji{
		display: block;margin-right: 0;line-height: 1;
	}
	&-subtit{
		font-size: 1.8rem;
	}
	&-case{
		margin-bottom: 25px;
	}
}

.ov{
	padding: 60px 0;
	&-box{
		padding: 0 7%;
	}
	&-tb{
		width: 100%;margin-bottom: 40px;
		th,td{padding: 0;}
		tbody{display: block;}
		tr{border-bottom: 1px solid rgba(255,255,255,0.2);padding-bottom: 10px;margin-bottom: 10px;}
		tbody th{width: 100%;font-size: 1.2rem;}
		.tel-wrap{
			color: #fff;text-decoration: underline;
		}
	}
	&-map{
		padding-bottom: 100%;
	}
}

