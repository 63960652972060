//small pc
@media only screen and (max-width: 1160px){
	.gnavi{
		&-jp{
			font-size: 1.6rem;
		}
		a{
			padding: 30px 16px 0;
		}
	}
}