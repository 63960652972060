//page

//トップページ
#home{


}

.key{
	height: 700px;background: url(../images/key.jpg) center center no-repeat;background-size:cover;position: relative;border-bottom: 5px solid $GREEN;
	&:before{
		content: "";width: 100%;height: 100%;background: url(../images/key_cover.png);background-size:2px;
		position: absolute;left:0;top:0;
	}
	&-box{
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);text-align: center;
		background: radial-gradient(circle farthest-side,#1c5a43 0%,#07402b 60%,#07402b 100%) center center no-repeat;box-shadow: rgba(0,0,0,0.15) 0 0 25px 0;
		color: #fff;padding: 70px 0;width: 800px;opacity: 0;
		animation:key-anime 0.8s linear 0.2s 1 normal both;
		&:before{
			content:"";width: 100%;height: 100%;position: absolute;top:0;left:0;
			border-style: solid;	border-width: 80px;
			border-image: url(../images/key_border.png) 160 160 160 160;
		}
	}
	&-tit{
		@extend .noto;font-size: 3.6rem;line-height: 1.4;margin-bottom: 45px;
		.s{font-size: 2.8rem;display: inline-block;position: relative;top:-4px;}
	}
	&-txt{
		@extend .noto;font-size: 2rem;
	}
}

.ab{
	padding: 80px 0;
	&-tit{
		margin-bottom: 125px;
	}
	&-box1{
		width:960px;padding-right: 390px;margin: 0 auto 90px;
		border-top: 1px solid $GREEN;padding-top: 40px;text-align: center;position: relative;
	}
	&-img1{
		position: absolute;left:570px;top:-60px;
	}
	&-subtit{
		@extend .noto;font-size: 1.9rem;line-height: 1.3;margin-bottom: 35px;color: $BLACK;
	}
	&-catch{
		@extend .noto;font-size: 2.3rem;color: $BLACK;
	}
	
	&-box2{
		width: 960px;margin: 0 auto;font-size: 1.8rem;padding-left: 325px;position: relative;
	}
	&-img2{
		position: absolute;left:-65px;top:0px;
	}
	&-lst{
		li{margin-bottom: 3px;padding-left: 14px;position: relative;
			&:before{
				content:"";background: $GREEN;width: 6px;height: 6px;border-radius:50%;position: absolute;left:2px;top:0.7em;
			}
		}
	}
}


.lw{
	padding-bottom: 80px;
	&-titbox{
		background: url(../images/bg_lawyer.jpg) center center no-repeat;background-size:cover;
		padding: 50px 0 185px;
	}
	.tit{
		&-en{color: #fff;}
		&-jp{color: #fff;}
	}
	&-box{
		width: 960px;margin: -130px auto 0;background: #fff;padding: 35px;
		display: flex;justify-content: space-between;
	}
	&-img{
		width: 300px;
	}
	&-prof{
		width: calc(100% - 335px);
	}
	&-name{
		color: $BLACK;margin-bottom: 20px;
	}
	&-post{
		display: block;line-height: 1.2;margin-bottom: 8px;
	}
	&-kanji{
		@extend .noto;display: inline-block;font-size: 3.6rem;vertical-align: middle;margin-right: 20px;
	}
	&-kana{
		@extend .noto;display: inline-block;font-size: 1.4rem;vertical-align: middle;
	}
	&-info{
		margin-bottom: 25px;
	}
	&-subtit{
		font-size: 2rem;line-height: 1.3;@extend .noto;color: $BLACK;border-bottom: 1px solid $BLACK;margin-bottom: 15px;
	}
	&-tb{
		display: flex;flex-wrap: wrap;
		&.lw-case{margin-bottom: 40px;}
		dt{color: $GREEN;width: 70px;margin-bottom: 10px;}
		dd{width: calc(100% - 70px);margin-bottom:10px;}
	}
}

.ov{
	background: url(../images/bg_overview.jpg) center center no-repeat;background-size:cover;
	padding: 80px 0;
	.tit{
		margin-bottom: 60px;
		&-en{color: #fff;}
		&-jp{color: #fff;}
	}
	&-box{
		width: 960px;margin: 0 auto;text-align: center;
	}
	&-tb{
		color: #fff;display: inline-block;margin: 0 auto 70px;
		th,td{padding: 15px 0;}
		tbody th{
			width: 130px;
		}
	}
	&-map{
		width: 100%;height: 0;padding-bottom: 500px;position: relative;
		iframe{
			position: absolute;top:0;left:0;width: 100%;height: 100%;opacity: 0;
		}
	}
}

.is-show{
	&.ov-map{
		&:before{
			animation: img-anime 1.2s $ease9 0.1s 1 normal both;
		}
		iframe{opacity: 1;transition:opacity 0s linear 0.7s;}
	}
}