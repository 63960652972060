//layout

// ラッパー
#wrapper{
	width: 100%;min-width: 1000px;overflow: hidden;
}

// ヘッダー
header{
	width: 100%;border-top: 5px solid #07402b;height: 100px;position: relative;
}
.logo{
	position: absolute;left:45px;top:30px;
}

// グローバルナビ
.navi-wrap{
	display: flex;justify-content: flex-end;
}
.gnavi{
	&-lst{
		display: flex;
		li{border-left: 1px solid $BDC;}
	}
	&-jp{display: block;@extend .noto;font-size: 1.7rem;line-height: 1;color: $BLACK;margin-bottom: 9px;transition:color 0.3s $ease5;}
	&-en{display: block;@extend .serif;font-size: 1.4rem;line-height: 1; color: $GREEN;transition:color 0.3s $ease5;}
	a{display: block;text-align: center;color: inherit;padding: 28px 32px 0;height: 95px;transition:background 0.3s $ease5;
		&:hover{
			text-decoration: none;background: $GREEN;
			.gnavi-jp{color: #fff;}
			.gnavi-en{color: #fff;}
		}
	}

}
.head-contact{
	padding: 0 14px;height: 95px;background: $YELLOW;color: #fff;text-align: center;padding-top: 14px;
	&-label{
		display: block;background: #b67e00;line-height: 1;padding: 5px 0;margin-bottom: 8px;
	}
	&-tel{
		@extend .noto;font-size: 2.6rem;padding-left: 24px;
		background: url(../images/icon_tel.png) 0 center no-repeat;background-size:22px;line-height: 1;
		letter-spacing: 0;
	}
}
.page-top{
	display: none;
}	
article{
	background: url(../images/bg.png);background-size:10px;
}

.main{

}


// フッター
.foot{
	background: url(../images/bg_foot.png);background-size:9px;
	border-top: 1px solid #fff;text-align: center;
	&-logo{
		padding-top: 55px;margin-bottom: 25px;
	}
	&-info{
		margin-bottom: 55px;color: #fff;font-size: 1.3rem;line-height: 1.5;
	}
}
.copyright{
	display: block;font-size: 1.1rem;color: #fff;background: $BLACK;padding: 10px 0;
}