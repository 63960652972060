//base
@import url('https://fonts.googleapis.com/css?family=EB+Garamond:500|Noto+Serif+JP:500&display=swap');


*,:before,:after{box-sizing:border-box; word-break: break-all;}
html{
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}

img{vertical-align: bottom;}
ol{padding-left:20px; list-style-type:decimal;font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体";}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:$FONT_SIZE;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.1em;font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}



.noto{
	font-family: YakuHanMP,'Noto Serif JP', serif;	font-weight: 500;letter-spacing: 0.1em;
}
.serif{
	font-family: 'EB Garamond', serif; font-weight: 500;letter-spacing: 0;
}
